import { useRef, useState } from 'react'
import Plyr from 'plyr'
import Player from './Player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

let askedForConfig = false

interface VideoProps {
  'input': {
    'ThumbnailURL': string,
    'Title': string,
    'URL': string,
    'DisplayName': string,
    'EndSeconds': number,
    'StartSeconds': number,
    'Message'?: string
  }
  TriggerData: {
    price: number
  }
}

export default function App() {
  const [vol, setVol] = useState(0.7)
  const [show, setShow] = useState(false)
  const [videoProps, setVideoProps] = useState<VideoProps>()
  const player = useRef<Plyr>()

  window.onmessage = (e) => {
    try {
      if (e.data.cmd === 'config') {
        setVol((old) => e.data.volume || old)
        console.log('setting config props')
        const configProps = e.data.config as VideoProps
        setVideoProps(configProps)
      }
      if (e.data.cmd === 'play') {
        console.log('running media share interaction')
        player.current!.volume = vol
        player.current!.muted = false
        player.current!.autoplay = true
        setShow(true)
        setTimeout(() => player.current!.play(), 500)
      }
    } catch (error) {
      console.error('error handling message, posting to iframe', error)
      window.parent.postMessage({ status: 'error', error: `${error}` }, '*')
    }
  }
  if (!askedForConfig) {
    askedForConfig = true
    window.parent.postMessage({ status: 'readyToConfig' }, '*')
  }

  if (!videoProps) return null

  const onEnded = () => {
    setShow(false)
    setTimeout(() =>
      window.parent.postMessage({ status: 'ended' }, '*'), 500)
  }
  const onReady = () => window.parent.postMessage({ status: 'readyToPlay' }, '*')

  const { DisplayName, URL, StartSeconds, EndSeconds, Message, Title } = videoProps.input

  return (
    <div
      className={classNames(
        'transition duration-500',
        show ? '' : 'opacity-0 translate-y-full',
        'h-[calc(100%-24px)] w-[calc(100%-150px)] my-2 mx-16 rounded-xl border-[#ADA7A7] bg-[#252424] text-[#ADA7A7]')}>
      <Player controllerRef={r => player.current = r} videoURL={URL} startSeconds={StartSeconds} endSeconds={EndSeconds}
              onEnded={onEnded} onReady={onReady} />
      <div className="mt-2 mx-4 text-xl flex gap-2 whitespace-nowrap">
        Sent by: <span className="font-medium text-[#D9D2D2]">{DisplayName}</span>
        <span className="text-[#D9D2D2] rounded-lg py-px px-2 bg-[#333131]"><FontAwesomeIcon
          icon={faStopwatch} /> {Math.round(EndSeconds - StartSeconds)}s</span>
        <span className="text-[#D9D2D2] rounded-lg py-px px-2 bg-[#333131]">${videoProps.TriggerData.price/100}</span>
        <div className="flex border-l border-gray-600" />
        <span className="truncate">{Title}</span>
      </div>
      <div className="mx-4 mt-2 text-xl truncate max-w-full">
        {Message}
      </div>
    </div>
  )
}
