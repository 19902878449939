import Plyr from 'plyr'
import { useEffect, useRef, useState } from 'react'

const ytVideoIDRegex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|shorts?|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/

let volume = 1

export default function Player({ videoURL, startSeconds, endSeconds, autoplay, onReady, onEnded, controllerRef }: {
  videoURL?: string;
  startSeconds?: number;
  endSeconds?: number;
  autoplay?: boolean;
  onReady?: () => void;
  onEnded?: (url: string) => void;
  controllerRef?: (p: Plyr) => void;
}) {
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null)
  const [currentTime, setCurrentTime] = useState(startSeconds)
  const [player, setPlayer] = useState<Plyr>()
  const videoReadyAt = useRef<Date>()
  const requestedPlay = useRef(false)
  const onReadyRef = useRef(onReady)
  onReadyRef.current = onReady
  const onEndedRef = useRef(onEnded)
  onEndedRef.current = onEnded
  player && controllerRef?.(player)

  useEffect(() => {
    if (!videoRef) return
    const p = new Plyr(videoRef, {
      // debug: true,
      controls: [],
      captions: {
        active: false
      },
      ratio: '1:1'
    })
    setPlayer(p)
    return () => {
      try {
        p.destroy()
      } catch {
      }
    }
  }, [videoRef])

  useEffect(() => {
    requestedPlay.current = false
    videoReadyAt.current = undefined
  }, [videoURL])

  useEffect(() => {
    if (!player || startSeconds === undefined || !endSeconds || !videoURL) return
    const p = player
    const readyCallback = () => {
      setTimeout(() => {
        videoReadyAt.current = new Date()
        if (autoplay || requestedPlay.current) {
          p.play()
        }
        p.volume = volume
        p.muted = !volume
        p.currentTime = startSeconds!
        onReadyRef.current?.()
      }, 300)
    }
    const timeCallback = () => {
      window.requestAnimationFrame(() => {
        setCurrentTime(p.currentTime)
        if (p.currentTime < startSeconds!) {
          p.currentTime = startSeconds!
          return
        }
        if (p.currentTime > endSeconds!) {
          // some YouTube videos just force-start at a different time than what we set on the ready callback and this fixes it
          if (!videoReadyAt.current || new Date().getTime() - videoReadyAt.current!.getTime() < 1_000) {
            p.currentTime = startSeconds!
            return
          }
          p.pause()
          p.currentTime = startSeconds!
          setCurrentTime(startSeconds)
          onEndedRef.current?.(videoURL!)
        }
      })
    }
    const endedCallback = () => {
      onEndedRef.current?.(videoURL!)
    }
    const volumeCallback = () => {
      volume = p.volume
    }
    const playCallback = () => {
      p.muted = false
    }
    const pauseCallback = () => {
    }
    const isYoutube = videoURL.includes('youtu')
    p.on('play', playCallback)
    p.on('pause', pauseCallback)
    if (isYoutube) {
      p.once('ready', readyCallback)
    } else {
      p.once('canplaythrough', readyCallback)
    }
    p.on('timeupdate', timeCallback)
    p.on('ended', endedCallback)
    p.on('volumechange', volumeCallback)

    // e.g. shorts only work if we extract the video ID
    const videoID = isYoutube ? videoURL.match(ytVideoIDRegex)?.[1]! : videoURL

    p.source = {
      type: 'video',
      sources: [
        {
          src: videoID,
          provider: isYoutube ? 'youtube' : 'html5'
        }
      ]
    }
    return () => {
      p.off('play', playCallback)
      p.off('pause', pauseCallback)
      if (isYoutube) {
        p.off('ready', readyCallback)
      } else {
        p.off('canplaythrough', readyCallback)
      }
      p.off('timeupdate', timeCallback)
      p.off('ended', endedCallback)
      p.off('volumechange', volumeCallback)
    }
  }, [player, videoURL, startSeconds, endSeconds, autoplay])

  return (
    <div className="relative group flex items-center flex-col h-[calc(100%-100px)] w-full">
      <div className="h-full w-full aspect-square mb-8">
        <div className="bg-black rounded-md overflow-hidden">
          <video ref={setVideoRef}>
            <div className="flex place-content-around">video loading...</div>
          </video>
        </div>
        <div className="mx-4 mt-2 rounded-full bg-[#212020] border border-[#403E3E]">
          <div className="bg-[#ADA7A7] rounded-full h-4" style={{
            width: `${Math.max(0.3, 100 * ((currentTime ?? 0) - (startSeconds ?? 0)) / ((endSeconds ?? 1) - (startSeconds ?? 0)))}%`
          }} />
        </div>
      </div>
    </div>
  )
}
